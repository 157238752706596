// 抽奖页面&报告会
const routes = [
	// 京东卡 兑奖页面
	{
		path: "/jdcard/:sign?",
		name: "jdcard",
		component: () => import("@/views/jdcard/index.vue"),
	},
	{
		path: "/jdcard20_new/:sign?",
		name: "jdcard20_new",
		component: () => import("@/views/jdcard20_new/index.vue"),
	},
	{
		path: "/jdcard20/:sign?",
		name: "jdcard20",
		component: () => import("@/views/jdcard20/index.vue"),
	},
	// 兑奖
	// {
	// 	path: "/prize/:sign?",
	// 	name: "prize",
	// 	component: () => import("@/views/prize/index.vue"),
	// },
	{
		path: "/address/:sign?",
		name: "address",
		component: () => import("@/views/address/index.vue"),
	},
	
]

export default routes